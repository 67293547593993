.transcriptomicPage {
  display: flex;
  height: calc(100vh - var(--main-header-height));
}

.transcriptomicPage .sideMenu li[role=menuitem] {
  color: white;
}

.transcriptomicPage .sideMenu li[role=menuitem] span[class$=-title-content] {
  margin-left: 0 !important;
}

.scrollContent {
  width: 100%;
  padding: var(--default-page-content-padding);
}

.pageContent {
  width: 100%;
}

.title {
  margin-bottom: 8px !important;
}

.subtitle {
  color: var(--gray-7);
}

.sideMenu {
  background-color: transparent;
}

.gridCard {
  padding: 0 !important;
}

.gridCard .gridCardContent {
  padding: 0 !important;
}

.content {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 16px;
  display: flex;
  flex: 1;
}

.containerDivider {
  display: flex;
  align-items: center;
  align-self: center;
}

.chartContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.vDivider {
  border-right: 1px solid var(--gray-4);
  width: 1px;
  height: 750px;
}

.header .vDivider {
  height: 59px;
}

.toggled {
  height: 120px !important;
}

.hDivider {
  margin: 0;
}

.swarmPlot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.sidebarSelect {
  width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  background-color: var(--blue-8);
  color: var(--gray-1);
}

.tag > * {
  color: var(--gray-1);
}