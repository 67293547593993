.footer {
  padding: 0 16px 16px 16px;
  display: flex;
  align-items: center;
}

.gene {
  display: flex;
  gap: 8px;
}

.sample {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
}