.wrapper {
  width: 100%;
  height: 100%;
  justify-self: center;
  align-self: center;
  max-width: 1200px;
}

.card {
  background-color: var(--gray-1) !important;
}

.cardContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: center;
  column-gap: 24px;
  row-gap: 16px;
  min-height: 150px;
  height: 100%;
}

.graphRowWrapper {
  height: 100%;
  width: 100%;
}

.pieChartWrapper {
  height: 90%;
}

@media (max-width: 800px) {
  .graphRowWrapper {
    flex-direction: column;
  }
  .colGraph {
    height: 100%;
  }
}
