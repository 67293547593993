.transcriptomicDataset {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 40%;
}

.datasetSelect {
  flex-grow: 1;
}

.aboutButton {
  padding: 0;
}

.datasetLabel {
  font-size: 16px;
  font-weight: 600;
}

.about {
  padding-left: 0;
}

.description {
  margin-bottom: 0 !important;
}

.contentTitle {
  margin-top: 24px !important;
  color: var(--gray-8) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 12px !important;
}

