.wrapper {
  width: 100%;
}

.summaryGrapCard {
  height: 100%;
}
.summaryGrapCard div[class$=-body] {
  overflow: unset !important;
}

.summaryGraphCardCol {
  width: 100%;
}
