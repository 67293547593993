.bottomBanner {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--gray-1);
  grid-column: span 2;
}

.content {
  background-color: var(--gray-3);
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

@media (min-width: 800px) and (max-width: 1024px) {
  .content {
    gap: 0;
  }
}
@media (max-width: 800px) {
  .bottomBanner {
    grid-column: span 1;
  }
  .content {
    flex-direction: column;
    gap: 0;
  }
}
