.topBanner {
  background-color: var(--gray-8);
  background-image: url("../../../components/assets/landing-page/page-header.jpg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 24px;
}

.contentContainer {
  display: flex;
  align-items: center;
  gap: 80px;
  max-width: 1200px;
  width: 100%;
}

.languageButton {
  position: absolute !important;
  top: 40px;
  right: 40px;
}

.content {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.logo {
  height: 104px;
  width: 300px;
}

.title {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  color: var(--gray-1);
}

.subtitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  color: var(--gray-1);
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--gray-1);
}

@media (min-width: 800px) and (max-width: 1024px) {
  .topBanner {
    padding: 40px 40px;
  }
  .contentContainer {
    gap: 40px;
  }
  .content {
    max-width: 720px;
  }
}
@media (max-width: 800px) {
  .topBanner {
    background-position: center;
    background-size: auto 100%;
    padding: 40px 40px;
  }
  .contentContainer {
    flex-direction: column;
    gap: 24px;
    max-height: fit-content;
  }
  .content {
    max-width: 520px;
  }
}
