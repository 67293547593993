.searchLabel.titleWrapper {
  color: var(--gray-9);
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.searchLabel.titleWrapper .title {
  font-size: 12px;
}
.searchLabel.titleWrapper .tooltipIcon {
  margin-left: 0.5rem;
  color: var(--gray-6);
}
