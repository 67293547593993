.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.title {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  color: var(--gray-1);
}

.subtitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  color: var(--gray-1);
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--gray-1);
}
