:root{
	--gray-1: #ffffff;
	--gray-2: #f8fafc;
	--gray-3: #f1f4f8;
	--gray-4: #dde4ee;
	--gray-5: #bcc9dc;
	--gray-6: #92a7c3;
	--gray-7: #5a77a0;
	--gray-8: #1c3863;
	--gray-8-r: 28;
	--gray-8-g: 56;
	--gray-8-b: 99;
	--gray-9: #072550;
	--gray-10: #001a3d;

	--blue-1: #f0f9ff;
	--blue-2: #e0f2fe;
	--blue-3: #bae6fd;
	--blue-4: #7dd3fc;
	--blue-5: #38bdf8;
	--blue-6: #0ea5e9;
	--blue-7: #0284c7;
	--blue-8: #0369a1;
	--blue-9: #075985;
	--blue-10: #0c4a6e;

	--red-1: #fcf6f5;
	--red-2: #f1d4cf;
	--red-3: #e6b2a9;
	--red-4: #db8f84;
	--red-5: #d06d5e;
	--red-6: #c54b38;
	--red-7: #a73928;
	--red-8: #86291b;
	--red-9: #631b10;
	--red-10: #3d0f08;

	--volcano-1: #fff2e8;
	--volcano-2: #ffd8bf;
	--volcano-3: #ffbb96;
	--volcano-4: #ff9c6e;
	--volcano-5: #ff7a45;
	--volcano-6: #fa541c;
	--volcano-7: #d4380d;
	--volcano-8: #ad2102;
	--volcano-9: #871400;
	--volcano-10: #610b00;

	--orange-1: #fff7e6;
	--orange-2: #ffe7ba;
	--orange-3: #ffd591;
	--orange-4: #ffc069;
	--orange-5: #ffa940;
	--orange-6: #fa8c16;
	--orange-7: #d46b08;
	--orange-8: #ad4e00;
	--orange-9: #873800;
	--orange-10: #612500;

	--gold-1: #fefce8;
	--gold-2: #fef9c3;
	--gold-3: #fef08a;
	--gold-4: #fde047;
	--gold-5: #facc15;
	--gold-6: #eab308;
	--gold-7: #ca8a04;
	--gold-8: #a16207;
	--gold-9: #854d0e;
	--gold-10: #713f12;

	--yellow-1: #feffe6;
	--yellow-2: #ffffb8;
	--yellow-3: #fffb8f;
	--yellow-4: #fff566;
	--yellow-5: #ffec3d;
	--yellow-6: #fadb14;
	--yellow-7: #d4b106;
	--yellow-8: #ad8b00;
	--yellow-9: #876800;
	--yellow-10: #614700;

	--lime-1: #fcffe6;
	--lime-2: #f4ffb8;
	--lime-3: #eaff8f;
	--lime-4: #d3f261;
	--lime-5: #bae637;
	--lime-6: #a0d911;
	--lime-7: #7cb305;
	--lime-8: #5b8c00;
	--lime-9: #3f6600;
	--lime-10: #254000;

	--green-1: #f5ffeb;
	--green-2: #d9f7bf;
	--green-3: #b7eb8e;
	--green-4: #94de63;
	--green-5: #73d13d;
	--green-6: #53c71a;
	--green-7: #39a00d;
	--green-8: #227704;
	--green-9: #135200;
	--green-10: #092900;

	--cyan-1: #e6fffb;
	--cyan-2: #b5f5ec;
	--cyan-3: #87e8de;
	--cyan-4: #5cdbd3;
	--cyan-5: #36cfc9;
	--cyan-6: #13c2c2;
	--cyan-7: #08979c;
	--cyan-8: #006d75;
	--cyan-9: #00474f;
	--cyan-10: #002329;

	--geekblue-1: #f0f5ff;
	--geekblue-2: #d6e4ff;
	--geekblue-3: #adc6ff;
	--geekblue-4: #85a5ff;
	--geekblue-5: #597ef7;
	--geekblue-6: #2f54eb;
	--geekblue-7: #1d39c4;
	--geekblue-8: #10239e;
	--geekblue-9: #061178;
	--geekblue-10: #030852;

	--purple-1: #f9f0ff;
	--purple-2: #efdbff;
	--purple-3: #d3adf7;
	--purple-4: #b37feb;
	--purple-5: #9254de;
	--purple-6: #722ed1;
	--purple-7: #531dab;
	--purple-8: #391085;
	--purple-9: #22075e;
	--purple-10: #120338;

	--magenta-1: #fff0f6;
	--magenta-2: #ffd6e7;
	--magenta-3: #ffadd2;
	--magenta-4: #ff85c0;
	--magenta-5: #f759ab;
	--magenta-6: #eb2f96;
	--magenta-7: #c41d7f;
	--magenta-8: #9e1068;
	--magenta-9: #780650;
	--magenta-10: #520339;

	/* Colors: */
	--preset-colors: pink, magenta, red, volcano, orange, yellow, gold, cyan, lime, green, blue, geekblue,
  purple;

}