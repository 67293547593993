.transcriptomicSearch {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
}

.searchContainer {
    width: 100%;
}

.select {
    width: 100%;
}

.searchContainer .tag {
  display: flex;
  align-items: center;
  background-color: var(--blue-8);
  color: var(--gray-1);
}

.searchContainer .tag > * {
  color: var(--gray-1);
}